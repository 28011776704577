/* FilamentTable.css */

/* Base styles for the table */
.table-wrapper {
  overflow-x: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.table-row { 
  cursor: pointer;
  
}

/* Responsive styles */
@media (max-width: 768px) {
  .table,
  .table thead,
  .table tbody,
  .table th,
  .table td,
  .table tr {
    display: block;
  }

  .table thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  .table tr {
    border: 1px solid #ccc;
    margin-bottom: 10px;
  }

  .table td {
    border: none;
    border-bottom: 1px solid #eee;
    position: relative;
    padding-left: 50%;
    text-align: right;
  }

  .table td:before {
    position: absolute;
    top: 50%;
    left: 10px;
    width: 45%;
    padding-right: 10px;
    white-space: nowrap;
    transform: translateY(-50%);
    text-align: left;
    font-weight: bold;
  }

  .table td:nth-of-type(1):before {
    content: "Brand";
  }
  .table td:nth-of-type(2):before {
    content: "Material Type";
  }
  .table td:nth-of-type(3):before {
    content: "Special Feature";
  }
  .table td:nth-of-type(4):before {
    content: "Color Name";
  }
  .table td:nth-of-type(5):before {
    content: "Finish";
  }
  .table td:nth-of-type(6):before {
    content: "Color Swatch";
  }
  .table td:nth-of-type(7):before {
    content: "Avg Price";
  }
  .table td:nth-of-type(8):before {
    content: "Diameter";
  }
  .table td:nth-of-type(9):before {
    content: "Diameter Tolerance";
  }
  .table td:nth-of-type(10):before {
    content: "Low Bed Temp";
  }
  .table td:nth-of-type(11):before {
    content: "High Bed Temp";
  }
  .table td:nth-of-type(12):before {
    content: "Low Nozzle Temp";
  }
  .table td:nth-of-type(13):before {
    content: "High Nozzle Temp";
  }
  .table td:nth-of-type(14):before {
    content: "Heated Bed Req.";
  }
  .table td:nth-of-type(15):before {
    content: "Spool Material";
  }
  .table td:nth-of-type(16):before {
    content: "Spool Diameter";
  }
  .table td:nth-of-type(17):before {
    content: "Empty Spool Weight";
  }
}