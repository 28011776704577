.filter-title {
    margin-bottom: 24px;
    font-weight: bold;
    text-align: left; /* Ensure title is left-aligned */
  }
  
  .filter-form {
    background: #f9f9f9; /* Light background for the filter area */
    padding: 16px; /* Padding around the filter section */
    border-radius: 8px; /* Rounded corners for the form */
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .ant-form-item {
    margin-bottom: 16px; /* Space between form items */
  }
  
  .ant-select {
    font-size: 16px; /* Larger font size for dropdown */
  }
  
  /* Media queries for responsive design */
  @media (max-width: 768px) {
    .filter-title {
      font-size: 24px; /* Adjust title size for smaller screens */
    }
  
    .ant-input {
      font-size: 16px; /* Larger input font for better readability */
    }
  }
  