.filament-card {
  margin-top: 4px;
  box-shadow: 0 0px 2px 0 rgba(0, 0, 0, 0.3);
  padding: 0px;  
  cursor: pointer;
}

/* Title styles */
.filament-card-title {
  margin-left: 5px;
  font-weight: normal;
  margin-top: 5px;
  letter-spacing: 0.1em;
}

/* Text styling for details */
.filament-card-details {
  display: inline-flex;
  color: #777777;
  margin-top: 1px;
  word-spacing: 0px;
  letter-spacing: 0em;
}

/* Media Queries */
@media (max-width: 600px) {
  .filament-card-title {
    font-size: 14px; /* Smaller size for mobile */
    word-wrap: break-word;
  }

  .filament-card-details {
    font-size: 12px; /* Smaller size for mobile */
  }
}

@media (min-width: 601px) {
  .filament-card-title {
    font-size: 24px; /* Larger size for desktop */
  }

  .filament-card-details {
    font-size: 16px; /* Default size for desktop */
  }
}
